import React from 'react';
import ReactDOM from 'react-dom';

let number = 99;
class Objects{

    setNumber(num){
        console.log('here is the GD number' + num);
        number = num;
    }


    getNumber(){
        console.log('number wang  ' +number);
        return number;
    }


}
const obj = new Objects();
export default obj;