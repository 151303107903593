import React, { Component  } from 'react';
import ReactDOM from 'react-dom';
//import Port from './Port';
import obj from './Objects';
import port1 from '../images/work_images/windtinder_work.jpg';
import port2 from '../images/work_images/tachy_work2.jpg';
import port3 from '../images/work_images/lens_work2.jpg';
import port4 from '../images/work_images/republic_rick2.jpg';
import port5 from '../images/work_images/hmy_work2.jpg';
import port6 from '../images/work_images/pointmassage_work.jpg';
import port7 from '../images/work_images/pots_work2.jpg';
import port8 from '../images/work_images/momni_work.jpg';
import port9 from '../images/work_images/nammi_work.jpg';
import port10 from '../images/work_images/apollo_work.png';
import port11 from '../images/work_images/scheduleit_work.jpg';
import port12 from '../images/work_images/scrollboy_work.jpg';
import port20 from '../images/work_images/abt_work.png';
import port21 from '../images/work_images/shrink_work.png';


class Work extends Component{
    constructor(props){
        super(props);
        this.fixOverlays = this.fixOverlays.bind(this);
        this.handleMouseOver = this.handleMouseOver.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
        this.closeOverlay = this.closeOverlay.bind(this);
        this.createClose = this.createClose.bind(this);
        this.overlayClick = this.overlayClick.bind(this);

        this.current_id =99;
        this.state = {
            height: 200 +"px",
            maxHeight: 700 +"px",
            width: 300 +"px",
            maxWidth: 700 +"px"
        }
    }

    handleClick(event){
        let el = event.currentTarget;

        console.log("here2 " + el.classList);
    }


    overlayClick(id) {
        obj.setNumber(id);
        obj.getNumber();
        console.log(this.current_id + " current id");

        if (id !== 99) {
            console.log(id + " clicked overlay");

            let old_port = document.querySelector('#port' + this.current_id);
            if(old_port.style.display !== 'none') {
                old_port.style.display = "none";
            }
            let port_header = document.querySelector('#port-header');
            console.log(port_header)
            port_header.style.display = "block";
            port_header.scrollIntoView();
            let port = document.querySelector('#port' + id);
            port.style.display = "flex";
            this.current_id = id;

        }
    }

    fixOverlays(){
        let el = document.querySelector('.work-image');
        let overlay = document.querySelector('.work-overlay');
        this.state.maxWidth =el.width +"px";
        this.state.width = el.width +"px";
        this.state.height = el.height +"px";
        this.state.maxHeight = el.height +"px";
        console.log(this.state.maxWidth);
        this.forceUpdate();

    }


    handleMouseOver(event) {
        //current object
        console.log('handling the mouse over baby');
        let el = event.currentTarget;
        //objects parent
        let el_parent = ReactDOM.findDOMNode(el).parentNode;
          if(el_parent.classList.contains('hover')){

              //already containes hover so do nothing
          //    console.log("contains hover already")
          }else {
              el_parent.classList.add('hover')
              //create overlay object
              let overlay = document.querySelector('#wb' + el.id);
              overlay.style.display = "block";
              this.fixOverlays();
          }
    }

    handleMouseLeave(event){
        //el will be the overlay
        let el = event.currentTarget;
        let el_parent = ReactDOM.findDOMNode(el).parentNode;
        if(el_parent.classList.contains('hover')){
            el_parent.classList.remove('hover')
        }
        console.log('mouse leave');
        //objects parent
        el.style.display ="none";
    }


    closeOverlay(id){
        console.log('should be closing id overlay ' + id);
        let overlay = document.querySelector('#wb' + id);
        overlay.style.display = "none";
        //let el_parent = ReactDOM.findDOMNode(overlay).parentNode;
            //el_parent.classList.remove('hover')

    }
    createClose(id){
        return <a className="close-overlay" onClick={() => this.closeOverlay(id)}>x</a>;
    }
    createClick(id, title){
        return <div className="promo-text" id={id} onClick={() => this.handleClick.bind(this)}>{title}<br/><br/>- See More -</div>
    }

    render() {
        return (
            <React.Fragment>
            <div className="content">
                <div className="header" id="work">
                    RECENT WORK
                </div>
            </div>
        <div className="work overlay-effect">
                <div className="work-box" >
                    <div onClick={() => this.overlayClick(10)} onMouseOut={ this.handleMouseLeave} className="work-overlay" id="wb10" style={{height:this.state.height,maxHeight:this.state.maxHeight,width:this.state.width,maxWidth:this.state.maxWidth}}>
                        {this.createClick("port-10","Windtinder")}
                        {this.createClose(10)}
                    </div>
                    <img src={port1} title="Windtinder"
                         className="work-image" id="10" alt="windtinder care pic" onMouseEnter={this.handleMouseOver} ontouchStart={this.handleMouseOver} />
                </div>
            <div className="work-box" >
                <div onClick={() => this.overlayClick(20)} onMouseOut={ this.handleMouseLeave} className="work-overlay" id="wb20" style={{height:this.state.height,maxHeight:this.state.maxHeight,width:this.state.width,maxWidth:this.state.maxWidth}}>
                    {this.createClick("port-20","Autonomic Training System")}
                    {this.createClose(20)}
                </div>
                <img src={port20} title="Autonomic Training System"
                     className="work-image" id="20" alt="Autonomic Training System" onMouseEnter={this.handleMouseOver} />
            </div>
            <div className="work-box" >
                <div onClick={() => this.overlayClick(21)} onMouseOut={ this.handleMouseLeave} className="work-overlay" id="wb21" style={{height:this.state.height,maxHeight:this.state.maxHeight,width:this.state.width,maxWidth:this.state.maxWidth}}>
                    {this.createClick("port-21","ShrinkIt")}
                    {this.createClose(21)}
                </div>
                <img src={port21} title="ShrinkIt"
                     className="work-image" id="21" alt="shrink it plugin" onMouseEnter={this.handleMouseOver} />
            </div>

        </div>
            <div className="work overlay-effect">
                <div className="work-box hidden-image">
                    <div onClick={() => this.overlayClick(11)} onMouseLeave={this.handleMouseLeave} className="work-overlay " id="wb11" style={{height:this.state.height,maxHeight:this.state.maxHeight,width:this.state.width,maxWidth:this.state.maxWidth}}>
                        {this.createClick("port-11","The Republic of Rick")}
                        {this.createClose(11)}
                    </div>
                    <img src={port4} className="work-image" id="11"
                         alt="republic of rick pic" onMouseEnter={this.handleMouseOver} />

                </div>
                <div className="work-box hidden-image">
                    <div onClick={() => this.overlayClick(4)} onMouseLeave={this.handleMouseLeave} className="work-overlay" id="wb4" style={{height:this.state.height,maxHeight:this.state.maxHeight,width:this.state.width,maxWidth:this.state.maxWidth}}>
                        {this.createClick("port-4","Handmade Yoga")}
                        {this.createClose(4)}
                    </div>
                    <img src={port5} className="work-image" id="4"
                         alt="handmade yoga image" onMouseEnter={this.handleMouseOver} />
                </div>
                <div className="work-box hidden-image">
                    <div onClick={() => this.overlayClick(18)} onMouseLeave={this.handleMouseLeave} className="work-overlay" id="wb18" style={{height:this.state.height,maxHeight:this.state.maxHeight,width:this.state.width,maxWidth:this.state.maxWidth}}>
                        {this.createClick("port-18","Point Massage")}
                        {this.createClose(18)}
                    </div>
                    <img src={port6} className="work-image"
                         id="18" alt="point massage norman pic" onMouseEnter={this.handleMouseOver} />
                </div>
            </div>

            <div className="work overlay-effect">

                <div className="work-box">
                    <div onClick={() => this.overlayClick(7)} onMouseLeave={this.handleMouseLeave} className="work-overlay" id="wb7" style={{height:this.state.height,maxHeight:this.state.maxHeight,width:this.state.width,maxWidth:this.state.maxWidth}}>
                        {this.createClick("port-7","POTS Treatment Program")}
                        {this.createClose(7)}
                    </div>
                    <img src={port7} className="work-image" id="7"
                         alt="pots pic" onMouseEnter={this.handleMouseOver} />

                </div>
                <div className="work-box">
                    <div onClick={() => this.overlayClick(8)} onMouseLeave={this.handleMouseLeave} className="work-overlay" id="wb8" style={{height:this.state.height,maxHeight:this.state.maxHeight,width:this.state.width,maxWidth:this.state.maxWidth}}>
                        {this.createClick("port-8","Momni Botique")}
                        {this.createClose(8)}
                    </div>
                    <img src={port8} className="work-image" id="8"
                         alt="momni pic" onMouseEnter={this.handleMouseOver} />
                </div>
                <div className="work-box">
                    <div onClick={() => this.overlayClick(9)} onMouseLeave={this.handleMouseLeave} className="work-overlay" id="wb9" style={{height:this.state.height,maxHeight:this.state.maxHeight,width:this.state.width,maxWidth:this.state.maxWidth}}>
                        {this.createClick("port-9","Nammi Truck")}
                        {this.createClose(9)}
                    </div>
                    <img src={port9} className="work-image" id="9"
                         alt="nammi pic" onMouseEnter={this.handleMouseOver} />
                </div>

            </div>


            <div className="work overlay-effect">

                <div className="work-box hidden-image">
                    <div onClick={() => this.overlayClick(13)} onMouseLeave={this.handleMouseLeave} className="work-overlay" id="wb13" style={{height:this.state.height,maxHeight:this.state.maxHeight,width:this.state.width,maxWidth:this.state.maxWidth}}>
                        <div className="promo-text" id="port-13">Apollo Deposition<br/><br/>- See More -</div>
                        <a className="close-overlay hidden">x</a></div>
                    <img src={port10} className="work-image" id="13"
                         alt="apollo depo pic" onMouseEnter={this.handleMouseOver} />

                </div>
                <div className="work-box">
                    <div onClick={() => this.overlayClick(14)} onMouseLeave={this.handleMouseLeave} className="work-overlay" id="wb14" style={{height:this.state.height,maxHeight:this.state.maxHeight,width:this.state.width,maxWidth:this.state.maxWidth}}>
                        <div className="promo-text" id="port-14">ScheduleIT<br/><br/>- See More -</div>
                        <a className="close-overlay hidden">x</a></div>
                    <img src={port11} className="work-image"
                         id="14" alt="scheduleit pic" onMouseEnter={this.handleMouseOver} />

                </div>

                <div className="work-box">
                    <div onClick={() => this.overlayClick(15)} onMouseLeave={this.handleMouseLeave} className="work-overlay" id="wb15" style={{height:this.state.height,maxHeight:this.state.maxHeight,width:this.state.width,maxWidth:this.state.maxWidth}}>
                        <div className="promo-text" id="port-15">ScrollBoy Plugin<br/><br/>- See More -</div>
                        <a className="close-overlay hidden">x</a></div>
                    <img src={port12} className="work-image" id="15"
                         alt="scrollboy pic" onMouseEnter={this.handleMouseOver} />

                </div>

            </div>
                <div className="work overlay-effect">
                    <div className="work-box" >
                    <div onClick={() => this.overlayClick(1)} onMouseLeave={this.handleMouseLeave} className="work-overlay" id="wb1" style={{height:this.state.height,maxHeight:this.state.maxHeight,width:this.state.width,maxWidth:this.state.maxWidth}}>
                        {this.createClick("port-1","Tachycardia Care")}
                        {this.createClose(1)}
                    </div>
                    <img src={port2} title="Tachycardia Care"
                         className="work-image" id="1" alt="tachycardia care pic"  onMouseOver={this.handleMouseOver}  />
                </div>
                    <div className="work-box">
                        <div onClick={() => this.overlayClick(6)} onMouseLeave={this.handleMouseLeave} className="work-overlay" id="wb6" style={{height:this.state.height,maxHeight:this.state.maxHeight,width:this.state.width,maxWidth:this.state.maxWidth}}>
                            {this.createClick("port-6","LENS")}
                            {this.createClose(6)}
                        </div>
                        <img src={port3} className="work-image" id="6"
                             alt="lens pic"  onMouseOver={this.handleMouseOver} />
                    </div>

                </div>
            </React.Fragment>
        );
    }
}
export default Work;