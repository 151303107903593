import React, { Component }  from 'react';
import ReactDOM from 'react-dom';
import Carousel from 'react-bootstrap/Carousel';
import MetaTags, {ReactTitle} from 'react-meta-tags';
import logo from './images/bi_logo_black.png';
import logow from './images/bi_logo_white.png';
import transparent_slide from './images/slide_dev_transparent.png';
import transparent_ux_slide from './images/slide_ux_transparent.png';
import Work from './components/Work';
import Port from './components/Port';
import  './sass/styles.scss';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import './App.scss';
import { Nav, Navbar} from 'react-bootstrap';
import { BsChevronRight,BsChevronLeft } from "react-icons/bs";




class DemoCarousel extends Component {
  render() {

    return (
        <Carousel nextLabel={""}
                  prevLabel={""}
                  nextIcon={directionButtons("Next")}
                  prevIcon={directionButtons("Previous")}
        >
          <Carousel.Item>
            <img src={transparent_slide} />
          </Carousel.Item>
          <Carousel.Item>
            <img src={transparent_ux_slide} />
          </Carousel.Item>
        </Carousel>
    );
  }
}


const directionButtons = (direction) => {

        if(direction === "Next") {
            return (<span className="slider-nav"><BsChevronRight /></span>);
        }else{
            return(<span className="slider-nav"><BsChevronLeft /></span>);
        }
};

function App()  {
  return (

    <div className="App">
        <React.Fragment>

            <MetaTags>
                <title>Burtinteractive</title>
                <meta id="meta-description" name="description" content="Web Development and Design Firm in Corvallis Oregon" />
                <meta id="og-title" property="og:title" content="Burtinteractive Development and Design" />
                <meta name="keywords" content="Website, Design, Logo Design, Application Development, Responsive websites, ios, android, ios applications, android applications, website design, corvallis, oregon" />
            </MetaTags>
        </React.Fragment>
      <div className="container-fluid">
        <Navbar collapseOnSelect expand="lg">
          <Navbar.Brand href="#home"><img src={logo} className="logo" alt="burtinteractive logo" /></Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link href="#home">Home</Nav.Link>
              <Nav.Link href="#about">About</Nav.Link>
              <Nav.Link href="#work">Work</Nav.Link>
              <Nav.Link href="#clients">Clients</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>

      <div className="container-fluid">
        <div className="bar">&nbsp;</div>
      </div>
      <div className="container-fluid slide">
        <DemoCarousel/>
      </div>

      <div className="container-fluid">
        <div className="bar">&nbsp;</div>

      </div>
        <div className="container-fluid">

            <Port/>

        </div>
        <div className="container-fluid">
            <Work/>
        </div>
      <div className="container-fluid">
        <div className="bar">&nbsp;</div>

      </div>
      <div className="container-fluid">
        <div className="content">
          <div className="header" id="about">
            ABOUT
          </div>
          <div className="content-text">
            <h3>Who we are</h3>
            <p>BurtInteractive is a development and design shop. We have more than 10 years of experience helping
              clients make their ideas functional and beautiful on the web. Need an app? Need a logo? Need it all? We
              can help. </p>
            <h3>Application Development</h3>
            <p>We offer custom application development for companies of all sizes in any industry. If you need a small
              eCommerce site, desktop application, iPhone app, or a large-scale enterprise solution, we can can
              recommend the best technology stack to fit your business needs. </p>
            <h3>Branding.</h3>
            Your application needs to function without fail, and look good doing it. We offer complete branding
            solutions from the ground up.

          </div>
        </div>

      </div>
      <div className="container-fluid">
        <div className="bar">&nbsp;</div>
      </div>

      <div className="container-fluid">
        <div className="content">
          <div className="header" id="clients">
            CLIENTS
          </div>
        </div>
        <div className="content-text row">
          <div className="work-box">
            Service Store USA, OR<br/>
            POTS Treatment Center, TX<br/>
            Momni Boutique, FL<br/>
            Nammi Truck, TX<br/>
            Sparkle Cleaning, TX<br/>

          </div>
          <div className="work-box">
            Santa Maria Capital, OR<br/>
            Bolton Fire & Rescue, NY<br/>
            Schmidt Lecture Series, TX<br/>
            Child Plus Pediatrics,TX<br/>
            Sloan Tutoring, TX<br/>
            Tucson Newspapers, AZ<br/>
          </div>
          <div className="work-box">
            Charting Nature, OR<br/>
            Handmade Yoga, TX<br/>
            Great Planning Tech, VT<br/>
            Child Plus Pediatrics,TX<br/>
            Ten80 Education, NY<br/>
            American Recording, NY<br/>
          </div>

        </div>


      </div>
      <div className="container-fluid">
        <div className="bar">&nbsp;</div>

      </div>
      <div className="container-fluid">
        <div className="footer">

          <img src={logow} className="logo" alt="burtinteractive development and design logo" />
            <h3 className="footer-text1"> Corvallis Oregon Design and Development Company</h3>
        </div>
      </div>
    </div>
  );
}

export default App;
