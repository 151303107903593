import React, { Component  } from 'react';
import ReactDOM from 'react-dom';
import scrollboy from '../images/portfolio/scrollboy_desktop.jpg'
import scheduleItD from '../images/portfolio/scheduleit_desktop.jpg';
import scheduleItM from "../images/portfolio/scheduleit_mobile.jpg"
import wind from '../images/portfolio/wt_desktop.jpg';
import windM from '../images/portfolio/wt_mobile.jpg';
import ustpa from '../images/portfolio/ustpa_desktop.jpg';
import ustpaM from '../images/portfolio/ustpa_mobile.jpg';
import tachy from '../images/portfolio/tacy_desktop.jpg';
import tachyM from '../images/portfolio/tacy_mobile.jpg';
import rick from '../images/portfolio/innver_vimo_image2_tag2.jpg';
import lens from '../images/portfolio/lens_main.jpg'
import points from '../images/portfolio/pointmassage_desktop.jpg'
import pointsM from '../images/portfolio/pointmassage_mobile.jpg';
import hand from '../images/portfolio/hmy_desktop.jpg';
import handM from '../images/portfolio/hmy_mobile.jpg';
import apollo from '../images/portfolio/apollo_desktop.jpg';
import apolloM from '../images/portfolio/apollo_mobile.jpg';
import momni from '../images/portfolio/momni_desktop.jpg';
import momniM from '../images/portfolio/momni_mobile.jpg';
import nammi from '../images/portfolio/nammi_desktop.jpg';
import nammiM from '../images/portfolio/nammi_mobile.jpg';
import pots from '../images/portfolio/pots_desktop.jpg';
import potsM from '../images/portfolio/pots_mobile.jpg';
import abt from '../images/portfolio/abt_desktop.jpg';
import abtM from '../images/portfolio/abt_mobile.jpg';
import shrink from '../images/portfolio/shrink_it_logo.jpg';
import obj from './Objects';

class Port extends Component {
    constructor(props) {
        super(props)
    }



    closePortfolio(id){


        obj.getNumber();
       // let currentNumber = this.props.getCurrentId();
       // let currentNumber = this.props;

        //console.log(this.props.test);
        //console.log(this.props.getCurrentId);
        //console.log(this.test);

        console.log("portfolio closing");
        let port_header = document.querySelector('#port-header');
        console.log(port_header)
        port_header.style.display = "none";
        let port = document.querySelector('#port'+obj.getNumber());
        console.log(port_header)
        port.style.display = "none";
    }

    render() {
        return (

            <React.Fragment>
                <div className="content" id="port-header" >
                    <div className="header col-lg-12" id="close" onClick={this.closePortfolio}>
                        X
                    </div>

                </div>
                <div className="portfolio-row" id="port10">

                    <div className="portfolio-mobile">

                        <div className="port-title">Windtinder</div>
                        <br/>
                        <p>Updated the windtinder website to make it responsive and give it a fresh new look. We also
                            built it out in Wordpress
                            to allow the user to update it easily themselves.
                        </p>

                        <a href="https://www.windtinder.com" target="_blank" className="visit-link">visit site</a>
                    </div>
                    <div className="portfolio-desktop">
                        <img src={wind} alt="windtinder desktop" />
                    </div>
                    <div className="portfolio-mobile">

                        <img src={windM} style={{width:'270px'}} alt="windtinder mobile" />
                    </div>


                </div>


                <div className="portfolio-row" id="port1">

                    <div className="portfolio-mobile">

                        <div className="port-title">Tachycardia Care</div>
                        <br/>
                        <p>
                            Task with creating graphics and designing an online micro site for the Tachycardia Care
                            Program.
                            We created several layouts of the site showing how it would look on mobile devices as well
                            as desktop computers.
                            We also created the logo at the top of the site as well and used in the promotional
                            materials.

                        </p>
                        <a href="https://burtinteractive.com/tachy/" target="_blank" className="visit-link">visit
                            site</a>
                    </div>
                    <div className="portfolio-desktop">
                        <img src={tachy} alt="tachycardia desktop" />
                    </div>
                    <div className="portfolio-mobile">

                        <img src={tachyM} alt="tachycardia mobile"/>
                    </div>


                </div>

                <div className="portfolio-row" id="port11">

                    <div className="portfolio-mobile">
                        <div className="port-title">The Republic of Rick</div>
                        <br/>
                        Developed graphics for the movie <i>The Republic of Rick</i> Viemo page. <br/><br/>

                        <a href="https://vimeo.com/ondemand/therepublicofrick" target="_blank" className="visit-link">visit
                            site</a>
                    </div>
                    <div className="portfolio-desktop">
                        <img src={rick} alt="republic of rick image" />
                    </div>


                    <div className="portfolio-mobile" style={{width:'20px',height:'20px'}}>&nbsp;&nbsp;
                    </div>

                </div>
                <div className="portfolio-row" id="port3">

                    <div className="portfolio-mobile">
                        <div className="port-title">The Republic of Rick</div>
                        <br/>
                        Developed graphics for the movie <i>The Republic of Rick</i> Viemo page. <br/><br/>

                        <a href="https://vimeo.com/ondemand/therepublicofrick" target="_blank" className="visit-link">visit
                            site</a>
                    </div>
                    <div className="portfolio-desktop">
                        <img src={rick} alt="republic of rick image" />
                    </div>


                    <div className="portfolio-mobile" style={{width:'20px',height:'20px'}}>&nbsp;&nbsp;
                    </div>

                </div>


                <div className="portfolio-row" id="port6">

                    <div className="portfolio-mobile">
                        <div className="port-title">LENS</div>
                        <br/>
                        <p>
                            LENS was a website done for an educational program done at TCU. It is now defunct but we
                            enjoyed doing the design and look for
                            this great program. This simple but effective site is clean and you can get all the
                            information you need in just a few simple clicks.

                        </p>

                    </div>
                    <div className="portfolio-desktop">
                        <img src={lens} alt="lens desktop" />
                    </div>


                    <div className="portfolio-mobile">

                    </div>

                </div>

                <div className="portfolio-row" id="port18">

                    <div className="portfolio-mobile">
                        <div className="port-title">Point Massage</div>
                        <br/>
                        <p>
                            Points Massage was a site done for a local masseuse. Several mockups of the logo and the
                            site were
                            created to show the client.
                        </p>

                    </div>
                    <div className="portfolio-desktop">
                        <img src={points}
                             alt="point massage norman desktop" />
                    </div>
                    <div className="portfolio-mobile">

                        <img src={pointsM}
                             alt="point massage norman mobile" />
                    </div>

                </div>

                <div className="portfolio-row" id="port4">

                    <div className="portfolio-mobile">

                        <div className="port-title">Handmade Yoga</div>
                        <br/>

                        <p>
                            This project was a lot of fun. We got to develop a logo for Handmade Yoga by taking in to
                            consideration of what they felt their brand was about and the values they represented.
                            We put together a photo-shoot for the products to use in promotional material and the
                            website.

                            We also created an online site that reflected the uniqueness of the Handmade Yoga brand. The
                            site was created to allow for an easy
                            online shopping experience. One can shop easily on their smart phone or their desktop
                            computer or tablet device.

                        </p>

                    </div>
                    <div className="portfolio-desktop">
                        <img src={hand}
                             alt="handmade yoga desktop" />
                    </div>
                    <div className="portfolio-mobile">

                        <img src={handM}
                             alt="handmade yoga mobile" />
                    </div>


                </div>
                <div className="portfolio-row" id="port5">

                    <div className="portfolio-mobile">

                        <div className="port-title">Handmade Yoga</div>
                        <br/>
                        <p>
                            This project was a lot of fun. We got to develop a logo for Handmade Yoga by taking in to
                            consideration of what they felt their brand was about and the values they represented.
                            We put together a photo-shoot for the products to use in promotional material and the
                            website.

                            We also created an online site that reflected the uniqueness of the Handmade Yoga brand. The
                            site was created to allow for an easy
                            online shopping experience. One can shop easily on their smart phone or their desktop
                            computer or tablet device.
                        </p>

                    </div>
                    <div className="portfolio-desktop">
                        <img src={hand}
                             alt="handmade yoga desktop" />
                    </div>
                    <div className="portfolio-mobile">

                        <img src={handM}
                             alt="handmade yoga mobile" />
                    </div>


                </div>

                <div className="portfolio-row" id="port7">

                    <div className="portfolio-mobile">

                        <div className="port-title">POTS Treatment Center</div>
                        <br/>
                        <p>
                            The POTS Treatment Center in Dallas needed to upgrade their website look and functionality
                            by moving away from WIX.
                            We were able to help them with this by creating a complete redesign and putting the power of
                            changing content in their hands
                            with the use of an easier content management system than WIX can offer.
                        </p>
                        <a href="https://www.potstreatmentcenter.com/" target="_blank" className="visit-link">visit
                            site</a>
                    </div>
                    <div className="portfolio-desktop">
                        <img src={pots}
                             alt="pots desktop" />
                    </div>
                    <div className="portfolio-mobile">

                        <img src={potsM} alt="pots mobile" />
                    </div>


                </div>


                <div className="portfolio-row" id="port8">

                    <div className="portfolio-mobile">

                        <div className="port-title">Momni Boutique</div>
                        <br/>
                        <p>
                            Created a simple landing page for Momni Boutique when they were just a bricks and mortar
                            establishment. Also developed
                            the logo and other branding items that were used in promotional items and store signage.
                        </p>
                    </div>
                    <div className="portfolio-desktop">
                        <img src={momni} alt="moni desktop" />
                    </div>
                    <div className="portfolio-mobile">

                        <img src={momniM} alt="momni mobile" />
                    </div>


                </div>


                <div className="portfolio-row" id="port9">

                    <div className="portfolio-mobile">

                        <div className="port-title">Nammi Truck</div>
                        <br/>
                        <p>
                            This was a fun project. Creating a website for a Dallas Food Truck and their new bricks and
                            mortar location.
                            We also had the opportunity to design the menus for each section of menu. We also developed
                            several t-shirts for Nammi as well.
                            Jobs like this are fun and provide a wide variety to show your skills.
                        </p>

                    </div>
                    <div className="portfolio-desktop">
                        <img src={nammi} alt="namme desktop" />
                    </div>
                    <div className="portfolio-mobile">

                        <img src={nammiM} alt="nammi mobile" />
                    </div>


                </div>


                <div className="portfolio-row" id="port13">

                    <div className="portfolio-mobile">

                        <div className="port-title">Apollo Deposition Services</div>
                        <br/>
                        <p>
                            A project to update the Apollo Deposition site to make it look more professional as well as
                            allow it to be viewed
                            easily on mobile devices. The site also needed to be easy to update from the CMS.
                        </p>
                    </div>
                    <div className="portfolio-desktop">
                        <img src={apollo} />
                    </div>
                    <div className="portfolio-mobile">

                        <img src={apolloM} alt="apollo mobile" />
                    </div>


                </div>
                <div className="portfolio-row" id="port16">

                    <div className="portfolio-mobile">

                        <div className="port-title">Apollo Deposition Services</div>
                        <br/>
                        <p>
                            A project to update the Apollo Deposition site to make it look more professional as well as
                            allow it to be viewed
                            easily on mobile devices. The site also needed to be easy to update from the CMS.
                        </p>
                    </div>
                    <div className="portfolio-desktop">
                        <img src={apollo} alt="apollo desktop" />
                    </div>
                    <div className="portfolio-mobile">

                        <img src={apolloM} />
                    </div>


                </div>

                <div className="portfolio-row" id="port14">

                    <div className="portfolio-mobile">

                        <div className="port-title">ScheduleIT</div>
                        <br/>
                        <p>
                            An open source project written in php and javascript to allow the same fuctionality as
                            Doodle. It can be configured with CAS and ldap. It is also responsive.
                            You can visit the github page to download or fork it <a
                            href="https://github.com/burtinteractive?tab=repositories" target="_blank">here</a>.
                        </p>
                        <a href="https://github.com/burtinteractive/scheduler" target="_blank" className="visit-link">visit
                            site</a>
                    </div>
                    <div className="portfolio-desktop">
                        <img src={scheduleItD}
                             alt="scheduleit desktop" />
                    </div>
                    <div className="portfolio-mobile">

                        <img src={scheduleItM}
                             alt="scheduleit mobile" />
                    </div>


                </div>

                <div className="portfolio-row" id="port15">

                    <div className="portfolio-mobile">

                        <div className="port-title">Scrollboy Plugin</div>
                        <br/>
                        <p>
                            An open source jquery plugin that allows you to create infinite scrolling.
                            You can visit the github page to download or fork it <a
                            href="https://github.com/burtinteractive?tab=repositories" target="_blank">here</a>.
                        </p>
                        <a href="https://github.com/burtinteractive/scrollboy" target="_blank" className="visit-link">visit
                            site</a>
                    </div>
                    <div className="portfolio-desktop">
                        <img src={scrollboy}
                             alt="scheduleit desktop" />
                    </div>


                </div>


                <div className="portfolio-row" id="port20">

                    <div className="portfolio-mobile">

                        <div className="port-title">Autonomic Balance System</div>
                        <br/>
                        <p>
                            A site to help people with tachycardia. Designed and implemented a custom login and learning tool on the site that allows
                            purchasers of the software to go through the online class and log their progress.

                        </p>
                        <a href="https://abtrainingsystem.com/" target="_blank" className="visit-link">visit
                            site</a>
                    </div>
                    <div className="portfolio-desktop">
                        <img src={abt}
                             alt="scheduleit desktop" />
                    </div>
                    <div className="portfolio-mobile">

                        <img src={abtM}
                             alt="scheduleit mobile" />
                    </div>


                </div>

                <div className="portfolio-row" id="port21">

                    <div className="portfolio-mobile">

                        <div className="port-title">ShrinkIt Plugin</div>
                        <br/>
                        <p>
                            An open source jquery plugin that allows you to shrink everything as your browser shrinks. Videos, navigation items or images. Can be applied to anything on your web page.
                            You can visit the github page to download or fork it <a
                            href="https://github.com/burtinteractive/shrinkit" target="_blank">here</a>.
                        </p>
                        <a href="https://github.com/burtinteractive/shrinkit" target="_blank" className="visit-link">visit
                            site</a>
                    </div>
                    <div className="portfolio-desktop">
                        <img src={shrink}
                             alt="shrinkit desktop" style={{width:'400px'}} />
                    </div>


                </div>
                <div className="portfolio-row" id="port99" style={{width:'0px',display:'block'}}>
                </div>


            </React.Fragment>
        )
    }
}
export default Port;